import axios from 'axios';

export const send = async (data) => {
  try{
    await axios.post('https://europe-west3-gbb-gcp.cloudfunctions.net/mood-tracking-backend',{
      mood: data.mood,
      direction: data.direction
    })
  } catch(err){
    //console.log(err.data)
  }
}
